import { Route } from "@solidjs/router";
import { Commitment, SignupFoundation, SignupTutors, TOC } from "../views";
import { COMMITMENT_PAGE, ROUTES, TOC_PAGE } from "../const";
import { SignupRoutesViewProps } from "../model";

export function SignupRoutes(props: SignupRoutesViewProps) {
  // console.log("Signup Page");
  return (
    <>
      <Route path={ROUTES.TOC} element={<TOC {...TOC_PAGE} />} />
      <Route path={ROUTES.COMMITMENT} element={<Commitment {...COMMITMENT_PAGE} />} />
      <Route path={ROUTES.SIGN_UP_TUTORS} element={<SignupTutors {...props} />} />
      <Route path={ROUTES.SIGN_UP_FOUNDATION} element={<SignupFoundation {...props} />} />
    </>
  );
}
